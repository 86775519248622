import axios from "axios";

const instance = axios.create({
  baseURL: "https://powerbek.uz/api/",
});

const header = {
  accept: "application/json",
  "X-CSRF-TOKEN": "",
  Authorization: `Bearer ${localStorage.getItem("token")}`,
};

export const history = async () => {
  const response = await instance.get("my-transactions", {
    headers: header,
  });
  return response.data.data;
};

export const stats = async (dateFrom = undefined, dateTo = undefined) => {
  const response = await instance.get("management/stats/user", {
    params: {
      date_from: dateFrom,
      date_to: dateTo,
    },
    headers: header,
  });
  return response.data.data;
};
