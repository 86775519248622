import { useState, useEffect, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import MyHistory from "./MyHistory";
import Statistics from "./Statistics";
import Users from "./Users";
import Stations from "./Stations";
import AddStation from "./AddStation";
import Bar from "../components/Bar";

function Home() {
  const navigate = useNavigate();
  const [currentPage, setCurrentPage] = useState("history");
  const [token, setToken] = useState(localStorage.getItem("token"));
  const [role, setRole] = useState(localStorage.getItem("role"));

  useEffect(() => {
    const handleStorage = () => {
      setToken(localStorage.getItem("token"));
      setRole(localStorage.getItem("role"));
    };
    window.addEventListener("storage", handleStorage);
    return () => window.removeEventListener("storage", handleStorage);
  }, []);

  useEffect(() => {
    if (!token) {
      navigate("/login");
    }
  }, [navigate, token]);

  useEffect(() => {
    localStorage.setItem("currentPage", currentPage);
  }, [currentPage]);

  const pageOptions = useMemo(() => {
    switch (currentPage) {
      case "history":
        return <MyHistory />;
      case "stats":
        return <Statistics />;
      case "users":
        return <Users />;
      case "stations":
        return <Stations />;
      case "addStation":
        return <AddStation />;
      default:
        return <div>Выберите страницу из меню</div>;
    }
  }, [currentPage]);

  const options = useMemo(
    () => (role === "admin" || role === "super_admin" ? 5 : 3),
    [role]
  );

  return (
    <div>
      <Bar options={options} setCurrentPage={setCurrentPage} />
      <div>{pageOptions}</div>
    </div>
  );
}

export default Home;
