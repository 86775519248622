import React, { useState, useEffect, useMemo, useCallback } from "react";
import { stats } from "../apis/stats";
import "../styles/Statistics.css";

function Statistics() {
  const [statsData, setStatsData] = useState({
    isLoading: true,
    error: "",
    totalTransactions: [],
    yearlyTransactions: [],
    monthlyTransactions: [],
    dailyTransactions: [],
    certainDateTransactions: [],
    from: "",
    to: "",
  });

  const date = new Date();
  const year = date.getFullYear();
  const month = date.getMonth() + 1;
  const day = date.getDate();
  const dateValues = useMemo(
    () => ({
      yearFrom: `${year}-01-01`,
      yearTo: `${year}-12-31`,
      monthFrom: `${year}-${month}-01`,
      monthTo: `${year}-${month}-${new Date(year, month, 0).getDate()}`,
      dayFrom: `${year}-${month}-${day}`,
      dayTo: `${year}-${month}-${day + 1}`,
      from: `${year}-${month}-${day}`,
      to: `${year}-${month}-${day}`,
    }),
    [year, month, day]
  );

  useEffect(() => {
    setStatsData((prev) => ({
      ...prev,
      from: dateValues.from,
      to: dateValues.to,
    }));
  }, [dateValues.from, dateValues.to]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [total, yearly, monthly, daily] = await Promise.all([
          stats(),
          stats(dateValues.yearFrom, dateValues.yearTo),
          stats(dateValues.monthFrom, dateValues.monthTo),
          stats(dateValues.dayFrom, dateValues.dayTo),
        ]);
        setStatsData((prev) => ({
          ...prev,
          isLoading: false,
          totalTransactions: total,
          yearlyTransactions: yearly,
          monthlyTransactions: monthly,
          dailyTransactions: daily,
        }));
      } catch (error) {
        setStatsData((prev) => ({
          ...prev,
          error: error.message,
          isLoading: false,
        }));
      }
    };
    fetchData();
  }, [dateValues]);

  const handleChange = useCallback(
    (type) => (event) => {
      setStatsData((prev) => ({ ...prev, [type]: event.target.value }));
    },
    []
  );

  const totalKwh = useCallback(
    (transactions) =>
      transactions
        .reduce((acc, t) => acc + parseFloat(t.total_used_power || 0), 0)
        .toFixed(2),
    []
  );

  const totalSum = useCallback(
    (transactions) =>
      transactions
        .reduce((acc, t) => acc + parseFloat(t.total_amount || 0), 0)
        .toFixed(2),
    []
  );

  const formatNumber = (number) => {
    return new Intl.NumberFormat("ru-RU").format(number);
  };

  const showData = async () => {
    try {
      const response = await stats(statsData.from, statsData.to);
      setStatsData((prev) => ({ ...prev, certainDateTransactions: response }));
    } catch (error) {
      setStatsData((prev) => ({
        ...prev,
        error: error.message,
        isLoading: false,
      }));
    }
  };

  return (
    <div className="statistics-container">
      <h1 className="statistics-header">Общая Статистика</h1>
      {statsData.isLoading ? (
        <p className="loading">Loading...</p>
      ) : statsData.error ? (
        <p className="error">Error: {statsData.error}</p>
      ) : (
        <div className="statistics-content">
          <div className="statistics-row">
            <div className="statistics-card">
              <h2>За всё время</h2>
              <p>
                Total Used kWh:{" "}
                {formatNumber(totalKwh(statsData.totalTransactions))}
              </p>
              <p>
                Total Sum: {formatNumber(totalSum(statsData.totalTransactions))}
              </p>
            </div>
            <div className="statistics-card">
              <h2>За этот год</h2>
              <p>
                Total Used kWh:{" "}
                {formatNumber(totalKwh(statsData.yearlyTransactions))}
              </p>
              <p>
                Total Sum:{" "}
                {formatNumber(totalSum(statsData.yearlyTransactions))}
              </p>
            </div>
          </div>
          <div className="statistics-row">
            <div className="statistics-card">
              <h2>За этот месяц</h2>
              <p>
                Total Used kWh:{" "}
                {formatNumber(totalKwh(statsData.monthlyTransactions))}
              </p>
              <p>
                Total Sum:{" "}
                {formatNumber(totalSum(statsData.monthlyTransactions))}
              </p>
            </div>
            <div className="statistics-card">
              <h2>За сегодня</h2>
              <p>
                Total Used kWh:{" "}
                {formatNumber(totalKwh(statsData.dailyTransactions))}
              </p>
              <p>
                Total Sum: {formatNumber(totalSum(statsData.dailyTransactions))}
              </p>
            </div>
          </div>
          <div className="statistics-form">
            <div>
              <label className="statistics-label" htmlFor="date-from">
                Date from:
              </label>
              <input
                className="statistics-input"
                type="date"
                id="date-from"
                value={statsData.from}
                onChange={handleChange("from")}
              />
            </div>
            <div>
              <label className="statistics-label" htmlFor="date-to">
                Date to:
              </label>
              <input
                className="statistics-input"
                type="date"
                id="date-to"
                value={statsData.to}
                onChange={handleChange("to")}
              />
            </div>
            <button className="statistics-button" onClick={showData}>
              Показать
            </button>
          </div>
          <div className="statistics-card">
            <h2>Selected Date Range</h2>
            <p>
              From: {statsData.from} To: {statsData.to}
            </p>
            <p>
              Total Used kWh:{" "}
              {formatNumber(totalKwh(statsData.certainDateTransactions))}
            </p>
            <p>
              Total Sum:{" "}
              {formatNumber(totalSum(statsData.certainDateTransactions))}
            </p>
          </div>
        </div>
      )}
    </div>
  );
}

export default Statistics;
