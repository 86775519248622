import { useState, useEffect } from "react";
import { utils, writeFile } from "xlsx";
import { station_stats } from "../apis/stations";
import "../styles/Users.css";

function StationStatistics({ stationID, stationName, handleClose }) {
  const [errorState, setError] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [page, setPage] = useState(1);
  const [stats, setStats] = useState([]);
  const [totalPages, setTotalPages] = useState(0);
  const [currentPageSet, setCurrentPageSet] = useState(1);
  const pagesPerSet = 10;

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      try {
        const response = await station_stats(stationID, page);
        setStats(response.data);
        setTotalPages(response.meta.last_page);
      } catch (error) {
        setError(error.message);
      } finally {
        setIsLoading(false);
      }
    };
    fetchData();
  }, [page, stationID]);

  const pageChange = (newPage) => {
    const newPageSet = Math.ceil(newPage / pagesPerSet);
    if (newPageSet !== currentPageSet) {
      setCurrentPageSet(newPageSet);
    }
    setPage(newPage);
  };

  const nextPageSet = () => {
    setCurrentPageSet(currentPageSet + 1);
  };

  const prevPageSet = () => {
    setCurrentPageSet(currentPageSet - 1);
  };

  const startPage = (currentPageSet - 1) * pagesPerSet + 1;
  const endPage = Math.min(startPage + pagesPerSet - 1, totalPages);

  const formatNumber = (number) => {
    return new Intl.NumberFormat("ru-RU").format(number);
  };

  const handleDownload = async () => {
    setIsLoading(true);
    let response = [];

    try {
      let count = 1;
      while (count <= totalPages) {
        const r = await station_stats(stationID, count);
        response = response.concat(r.data);
        count++;
      }
    } catch (error) {
      setError("Failed to get station data, ", error.message);
    } finally {
      setIsLoading(false);
    }

    const worksheet = utils.json_to_sheet(response);
    const workbook = utils.book_new();
    utils.book_append_sheet(workbook, worksheet, "Sheet1");

    writeFile(workbook, `${stationName}.xlsx`);
  };

  return (
    <div className="Users">
      <h1 className="users-header">Статистика станции: {stationID}</h1>
      <button
        onClick={() => {
          setStats([]);
          setTotalPages(0);
          handleClose();
        }}
      >
        Назад
      </button>
      {isLoading ? (
        <p className="loading">Loading...</p>
      ) : errorState ? (
        <p className="error">Error: {errorState}</p>
      ) : (
        <>
          <table>
            <thead>
              <tr>
                <th>ID коннектора</th>
                <th>Начало загрузки</th>
                <th>Номер клиента</th>
                <th>Использованно kWh</th>
                <th>Сумма</th>
              </tr>
            </thead>
            <tbody>
              {stats.length === 0 ? (
                <tr>
                  <td>No Records</td>
                </tr>
              ) : (
                stats.reverse().map((record, index) => (
                  <tr key={index}>
                    <td>{record.connector_pk}</td>
                    <td>{record.start_timestamp.replace(/\.\d+$/, "")}</td>
                    <td>+{record.phone}</td>
                    <td>{record.used_kwh}</td>
                    <td>{formatNumber(record.sum)}</td>
                  </tr>
                ))
              )}
            </tbody>
            <button onClick={handleDownload}>Download XLSX</button>
          </table>
          <div className="pagination">
            {currentPageSet > 1 && <button onClick={prevPageSet}>{"<"}</button>}
            {Array.from({ length: endPage - startPage + 1 }, (_, i) => (
              <button
                key={startPage + i}
                className={page === startPage + i ? "active" : ""}
                onClick={() => pageChange(startPage + i)}
              >
                {startPage + i}
              </button>
            ))}
            {endPage < totalPages && (
              <button onClick={nextPageSet}>{">"}</button>
            )}
          </div>
        </>
      )}
    </div>
  );
}

export default StationStatistics;
