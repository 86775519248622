import { useState, useEffect } from "react";
import { stations_list } from "../apis/stations";
import "../styles/Stations.css";
import StationStatistics from "../components/StationStatistics";
import StationSettings from "../components/StationSettings";

function Stations() {
  const [errorState, setError] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [stations, setStations] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [stationsPerPage] = useState(10);
  const [otherPage, setOtherPage] = useState(false);
  const [currentPageName, setCurrentPageName] = useState("");
  const [stationID, setStationID] = useState("");
  const [stationName, setStationName] = useState("");

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await stations_list();
        setStations(response);
      } catch (error) {
        setError(error.message);
      } finally {
        setIsLoading(false);
      }
    };
    fetchData();
  }, []);

  const handlePageOpen = (type, stationID, stationName) => {
    setOtherPage(true);
    setCurrentPageName(type);
    setStationID(stationID);
    setStationName(stationName);
  };

  const handlePageClose = () => {
    setOtherPage(false);
  };

  const renderOtherPage = () => {
    switch (currentPageName) {
      case "stats":
        return (
          <StationStatistics
            stationID={stationID}
            stationName={stationName}
            handleClose={handlePageClose}
          />
        );
      case "settings":
        return (
          <StationSettings
            stationID={stationID}
            handleClose={handlePageClose}
          />
        );
      default:
        return null;
    }
  };

  if (otherPage) {
    return renderOtherPage();
  }

  const indexOfLastStation = currentPage * stationsPerPage;
  const indexOfFirstStation = indexOfLastStation - stationsPerPage;
  const currentStations = stations.slice(
    indexOfFirstStation,
    indexOfLastStation
  );

  const stationsRender = () => {
    return currentStations.map((station) => (
      <tr key={station.charge_box_id}>
        <td>{station.description}</td>
        <td>{station.charge_box_id}</td>
        <td>
          {station.address.city}, {station.address.street}
        </td>
        <td>+{station.phone}</td>
        <td>{/* {station.working_time.monday} */}</td>
        <td>{station.power} кВт</td>
        <td>
          <button
            onClick={() => {
              handlePageOpen(
                "stats",
                station.charge_box_id,
                station.description
              );
            }}
          >
            Показать
          </button>
        </td>
        <td>
          <button>Изменить</button>
        </td>
        <td>
          <button
            onClick={() => {
              handlePageOpen(
                "settings",
                station.charge_box_id,
                station.description
              );
            }}
          >
            Показать
          </button>
        </td>
      </tr>
    ));
  };

  // Change page function
  const paginate = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  // Create page numbers
  const pageNumbers = [];
  for (let i = 1; i <= Math.ceil(stations.length / stationsPerPage); i++) {
    pageNumbers.push(i);
  }

  return (
    <div className="Stations">
      <h1 className="stations-header">База данных станций</h1>
      {isLoading ? (
        <p className="loading">Loading...</p>
      ) : errorState ? (
        <p className="error">Error: {errorState}</p>
      ) : (
        <>
          <table>
            <thead>
              <tr>
                <th>Описание</th>
                <th>ID зарядного блока</th>
                <th>Адрес</th>
                <th>Телефон</th>
                <th>Рабочие часы</th>
                <th>Мощность</th>
                <th>Статистика</th>
                <th>Фото</th>
                <th>Настройки</th>
              </tr>
            </thead>
            <tbody>{stationsRender()}</tbody>
          </table>
          <div className="pagination">
            {pageNumbers.map((number) => (
              <button
                key={number}
                className={currentPage === number ? "active" : ""}
                onClick={() => paginate(number)}
              >
                {number}
              </button>
            ))}
          </div>
        </>
      )}
    </div>
  );
}

export default Stations;
