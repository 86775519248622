import { useState, useEffect, useCallback } from "react";
import "../styles/SideBar.css";
import {
  station_by_id,
  update_station,
  update_connector,
  activate_connector,
} from "../apis/stations";

function PageInfo({ station }) {
  return (
    <div>
      <div className="card-container">
        <div className="card">
          <h2>General Information</h2>
          <p>Vendor: {station.charge_point_vendor}</p>
          <p>Model: {station.charge_point_model}</p>
          <p>Phone: +{station.phone}</p>
          <p>Brand: {station.brand}</p>
        </div>

        <div className="card">
          <h2>Technical Details</h2>
          <p>Serial Number: {station.charge_point_serial_number}</p>
          <p>Firmware Version: {station.ocpp_protocol}</p>
        </div>

        <div className="card">
          <h2>Station Status</h2>
          <p>Registration Status: {station.registration_status}</p>
          <p>Last Heartbeat: {station.last_heartbeat_timestamp}</p>
          <p>Status: {station.status || "Unknown"}</p>
        </div>

        {station.connectors?.map((connector, index) => (
          <div className="card" key={index}>
            <h2>Connector {connector.connector_id}</h2>
            <p>
              Status:{" "}
              <span
                className={`connector-status ${connector.connector_status.status.toLowerCase()}`}
              >
                {connector.connector_status.status}
              </span>
            </p>
            <p>Error Code: {connector.connector_status.error_code}</p>
            <p>Type: {connector.connector_type}</p>
            <p>Max Power: {connector.max_power} kW</p>
            <p>Price per kWh: {connector.price_kwh}</p>
          </div>
        ))}

        <div className="card">
          <h2>Working Time</h2>
          <p>Monday: {station.working_time?.monday}</p>
          <p>Tuesday: {station.working_time?.tuesday}</p>
          <p>Wednesday: {station.working_time?.wednesday}</p>
          <p>Thursday: {station.working_time?.thursday}</p>
          <p>Friday: {station.working_time?.friday}</p>
          <p>Saturday: {station.working_time?.saturday}</p>
          <p>Sunday: {station.working_time?.sunday}</p>
        </div>
      </div>
    </div>
  );
}

function PageConnectors({ station }) {
  const [isLoading, setIsLoading] = useState(false);
  const [errorState, setError] = useState("");
  const [open, setOpen] = useState({ isOpen: false, connector: {} });
  const [formData, setFormData] = useState({
    connector_type: "",
    max_power: 0,
    price_kwh: "",
  });

  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsLoading(true);
    try {
      await update_connector(open.connector.connector_pk, formData);
    } catch (error) {
      setError(`Failed to update connector: ${error.message}`);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="card-container">
      {open.isOpen ? (
        <div>
          <h1>Connector: {open.connector.connector_id}</h1>
          <form>
            <div>
              <label htmlFor="name">Type:</label>
              <input
                type="text"
                id="type"
                value={formData.connector_type}
                onChange={(e) =>
                  setFormData((prev) => ({
                    ...prev,
                    connector_type: e.target.value,
                  }))
                }
              />
            </div>
            <div>
              <label htmlFor="power">Power:</label>
              <input
                type="number"
                id="power"
                value={formData.max_power}
                onChange={(e) =>
                  setFormData((prev) => ({
                    ...prev,
                    max_power: e.target.value,
                  }))
                }
              />
            </div>
            <div>
              <label htmlFor="cost">Cost:</label>
              <input
                type="text"
                id="cost"
                value={formData.price_kwh}
                onChange={(e) =>
                  setFormData((prev) => ({
                    ...prev,
                    price_kwh: e.target.value,
                  }))
                }
              />
            </div>
            <button
              onClick={async () => {
                setIsLoading(true);
                try {
                  await activate_connector(open.connector.connector_id, {
                    status: 1,
                  });
                } catch (error) {
                  setError(`Failed to activate connector: ${error.message}`);
                } finally {
                  setIsLoading(false);
                }
              }}
            >
              Acivate connector
            </button>
            <button
              onClick={async () => {
                setIsLoading(true);
                try {
                  await activate_connector(open.connector.connector_id, {
                    status: 0,
                  });
                } catch (error) {
                  setError(`Failed to activate connector: ${error.message}`);
                } finally {
                  setIsLoading(false);
                }
              }}
            >
              Deactivate connector
            </button>
            <button onClick={handleSubmit}>Submit</button>
          </form>
        </div>
      ) : (
        station.connectors?.map((connector, index) => (
          <div className="card" key={index}>
            <h2>Connector {connector.connector_id}</h2>
            <p>
              Status:{" "}
              <span
                className={`connector-status ${connector.connector_status.status.toLowerCase()}`}
              >
                {connector.connector_status.status}
              </span>
            </p>
            <p>Error Code: {connector.connector_status.error_code}</p>
            <p>Type: {connector.connector_type}</p>
            <p>Max Power: {connector.max_power} kW</p>
            <p>Price per kWh: {connector.price_kwh}</p>
            <button
              onClick={() =>
                setOpen((prev) => ({
                  ...prev,
                  isOpen: true,
                  connector: connector,
                }))
              }
            >
              Изменить
            </button>
          </div>
        ))
      )}
      {errorState && <p className="error">Error: {errorState}</p>}
      {isLoading && <p className="loading">Loading...</p>}
    </div>
  );
}

const names = {
  phone: "Номер телефона",
  min_deposit: "Минимальный депозит",
  price_simple: "Цена застоя",
  brand: "Бренд",
  street: "Улица",
  house_number: "Номер дома",
  zip_code: "Zip код",
  city: "Город",
  country: "Страна",
  charge_box_id: "ID станции",
  description: "Описание",
  latitude: "Широта",
  longitude: "Долгота",
  user_id: "ID партнера",
  power: "Мощность",
  partner_percent: "Доля партнера (%)",
};

function FormInput({ name, label, value, onChange }) {
  return (
    <div>
      <p>{label}:</p>
      <input name={name} value={value} onChange={onChange} />
    </div>
  );
}

function PageEdit({ station }) {
  const [isLoading, setIsLoading] = useState(false);
  const [errorState, setError] = useState("");
  const [formData, setFormData] = useState(() =>
    Object.keys(names).reduce((acc, key) => ({ ...acc, [key]: "" }), {})
  );

  const handleChange = useCallback((event) => {
    const { name, value } = event.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  }, []);

  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsLoading(true);
    try {
      await update_station(station.charge_point_serial_number, formData);
    } catch (error) {
      setError(`Failed to add station: ${error.message}`);
    } finally {
      setIsLoading(false);
    }
  };
  return (
    <div>
      <form onSubmit={handleSubmit}>
        <div className="form-grid">
          {Object.entries(names).map(([key, label]) => (
            <FormInput
              key={key}
              name={key} // Add name attribute here
              label={label}
              value={formData[key]}
              onChange={handleChange}
            />
          ))}
        </div>
        <button type="submit" disabled={isLoading}>
          Обновить
        </button>
      </form>
      {errorState && <p className="error">Error: {errorState}</p>}
      {isLoading && <p className="loading">Loading...</p>}
    </div>
  );
}

function StationSettings({ stationID, handleClose }) {
  const [isLoading, setIsLoading] = useState(false);
  const [errorState, setError] = useState("");
  const [station, setStation] = useState({});
  const [page, setPage] = useState({ type: "Info", isOpen: false });

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      try {
        const response = await station_by_id(stationID);
        setStation(response);
      } catch (error) {
        setError(`Failed to load page: ${error.message}`);
      } finally {
        setIsLoading(false);
      }
    };
    fetchData();
  }, [stationID]);

  const handleClick = (type) => {
    setPage((prev) => ({ ...prev, type, isOpen: true }));
  };

  return (
    <div className="Stations">
      <h1 className="stations-header">Station settings</h1>
      <button
        onClick={() => {
          handleClose();
        }}
      >
        Назад
      </button>
      {isLoading ? (
        <p className="loading">Loading...</p>
      ) : errorState ? (
        <p className="error">Error: {errorState}</p>
      ) : (
        <div>
          <div className="sidebar">
            <button onClick={() => handleClick("Info")}>Info</button>
            <button onClick={() => handleClick("Connectors")}>
              Connectors
            </button>
            <button onClick={() => handleClick("Edit")}>Edit</button>
          </div>
          {page.type === "Info" ? (
            <PageInfo station={station} />
          ) : page.type === "Connectors" ? (
            <PageConnectors station={station} />
          ) : (
            <PageEdit station={station} />
          )}
        </div>
      )}
    </div>
  );
}

export default StationSettings;
