import axios from "axios";

const instance = axios.create({
  baseURL: "https://powerbek.uz/api/",
});

const header = {
  accept: "application/json",
  "X-CSRF-TOKEN": "",
  Authorization: `Bearer ${localStorage.getItem("token")}`,
};

export const stations_list = async () => {
  const response = await instance.get("charge-box", {
    headers: header,
  });
  return response.data.data;
};

export const station_by_id = async (chargeBoxID) => {
  const response = await instance.get(`charge-box/${chargeBoxID}`);

  return response.data.data;
};

export const station_stats = async (stationID, page) => {
  const response = await instance.get(
    `management/stats/charge-box/${stationID}`,
    {
      headers: header,
      params: {
        page,
      },
    }
  );

  return response.data;
};

export const add_station = async (data) => {
  await instance.post("management/add-chargebox", data, {
    headers: header,
  });
};

export const update_station = async (stationID, data) => {
  await instance.put(`management/update-chargebox/${stationID}`, data, {
    headers: header,
  });
};

export const update_connector = async (connector, data) => {
  await instance.post(`management/edit-connector/${connector}`, data, {
    headers: header,
  });
};

export const activate_connector = async (connector, data) => {
  await instance.post(`management/change-status/${connector}`, data, {
    headers: header,
  });
};
