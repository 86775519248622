import React, { useState, useEffect, useMemo } from "react";
import { history } from "../apis/stats";
import "../styles/History.css"; // Make sure the CSS file is correctly imported

function MyHistory() {
  const [errorState, setError] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [myTransactions, setMyTransactions] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await history();
        setMyTransactions(response.reverse());
      } catch (error) {
        setError(error.message);
      } finally {
        setIsLoading(false);
      }
    };
    fetchData();
  }, []);

  const totalKwh = useMemo(
    () =>
      myTransactions.reduce(
        (accum, transaction) => accum + parseFloat(transaction.used_kwh || 0),
        0
      ),
    [myTransactions]
  );
  const totalSum = useMemo(
    () =>
      myTransactions.reduce(
        (accum, transaction) => accum + parseFloat(transaction.sum || 0),
        0
      ),
    [myTransactions]
  );
  const totalTransactions = myTransactions.length;

  const formatNumber = (number) => {
    return new Intl.NumberFormat("ru-RU").format(number);
  };

  return (
    <div className="my-history-container">
      <h1 className="my-history-header">Моя история</h1>
      {isLoading ? (
        <p className="loading">Loading...</p>
      ) : errorState ? (
        <p className="error">Error: {errorState}</p>
      ) : (
        <>
          <div className="my-history-summary">
            <div>
              <h3>Total Used kWh</h3>
              <p>{totalKwh.toFixed(2)}</p>
            </div>
            <div>
              <h3>Total Transactions</h3>
              <p>{totalTransactions}</p>
            </div>
            <div>
              <h3>Total Sum</h3>
              <p>{formatNumber(totalSum.toFixed(2))}</p>
            </div>
          </div>
          <table className="table">
            <thead>
              <tr>
                <th>Начало загрузки</th>
                <th>Connector ID</th>
                <th>Used kWh</th>
                <th>Sum</th>
              </tr>
            </thead>
            <tbody>
              {myTransactions.map((transaction) => (
                <tr key={transaction.transaction_pk}>
                  <td>
                    {transaction.start_timestamp
                      .replace("T", " ")
                      .replace(/\.000000Z$/, "")}
                  </td>
                  <td>{transaction.connector_pk}</td>
                  <td>{transaction.used_kwh}</td>
                  <td>{formatNumber(transaction.sum)}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </>
      )}
    </div>
  );
}

export default MyHistory;
