import axios from "axios";

const instance = axios.create({
  baseURL: "https://powerbek.uz/api/management/",
});

const header = {
  accept: "application/json",
  "X-CSRF-TOKEN": "",
  Authorization: `Bearer ${localStorage.getItem("token")}`,
};

export const users_list = async (page) => {
  const response = await instance.get("user", {
    headers: header,
    params: {
      page,
    },
  });
  return response.data;
};

export const user_by_id = async (userID) => {
  const response = await instance.get(`stats/user/${userID}`, {
    headers: header,
  });
  return response.data.data;
};

export const search_user = async (phone) => {
  const response = await instance.post(
    "user-phone",
    { phone },
    {
      headers: header,
    }
  );

  return response.data.data;
};

export const edit_user = async (userID) => {
  const response = await instance.put(`user/${userID}`, {
    headers: header,
  });

  return response.data.data;
};

export const balance_update = async (userID, amount, action) => {
  const response = await instance.post(
    `balance-update/${userID}`,
    { amount },
    {
      headers: header,
      params: { action },
    }
  );

  return response.data.data;
};
