import { useState, useCallback } from "react";
import "../styles/Users.css";
import { edit_user } from "../apis/users";

const names = {
  name: "Имя",
  surname: "Фамилия",
  email: "Email",
  password: "Пароль",
  password_confirmation: "Подтвердите пароля",
  phone: "Номер телефона",
  role: "Роль",
};

function FormInput({ label, name, value, onChange }) {
  return (
    <div>
      <p>{label}:</p>
      <input name={name} value={value} onChange={onChange} />
    </div>
  );
}

function UserEdit({ userID, userPhone, handleClose }) {
  const [errorState, setError] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [formData, setFormData] = useState(() =>
    Object.keys(names).reduce((acc, key) => ({ ...acc, [key]: "" }), {})
  );

  const handleChange = useCallback((event) => {
    const { name, value } = event.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  }, []);

  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsLoading(true);
    try {
      await edit_user(userID, formData); // Pass formData to the API call
    } catch (error) {
      setError(`Failed to edit user: ${error.message}`);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="Users">
      <h1 className="users-header">Изменить пользователя: +{userPhone}</h1>
      <button
        onClick={() => {
          handleClose();
        }}
      >
        Назад
      </button>
      {isLoading ? (
        <p className="loading">Loading...</p>
      ) : errorState ? (
        <p className="error">Error: {errorState}</p>
      ) : (
        <form onSubmit={handleSubmit}>
          <div className="form-grid">
            {Object.entries(names).map(([key, label]) => (
              <FormInput
                key={key}
                name={key} // Add name attribute
                label={label}
                value={formData[key]}
                onChange={handleChange}
              />
            ))}
          </div>
          <button type="submit" disabled={isLoading}>
            Изменить
          </button>
        </form>
      )}
    </div>
  );
}

export default UserEdit;
