import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { login } from "../apis/auth";
import "../styles/Login.css";

function Login() {
  const navigate = useNavigate();
  const token = localStorage.getItem("token");
  const [phoneInput, setPhoneInput] = useState("+998");
  const [phone, setPhone] = useState("");
  const [password, setPassword] = useState("");
  const [errorState, setError] = useState("");

  useEffect(() => {
    if (token) {
      navigate("/");
    }
  }, [navigate, token]);

  const handlePhoneNumber = (event) => {
    const numericValue = event.target.value.replace(/[^\d]/g, "");
    if (numericValue.length <= 12) {
      setPhoneInput(`+${numericValue}`);
      setPhone(numericValue);
    }
  };

  const handlePassword = (event) => {
    setPassword(event.target.value);
  };

  const handleClick = async (event) => {
    event.preventDefault();

    if (phone.length !== 12) {
      setError("Phone number must contain 12 digits");
      return;
    }

    try {
      const response = await login(phone, password);
      if (response.status === 200) {
        if (localStorage.getItem("role") === ("client" || "partner")) {
          setError("Access denied.");
        } else {
          navigate("/");
        }
      } else {
        throw new Error("Failed to login");
      }
    } catch (error) {
      setError(error.message);
    }
  };

  return (
    <div className="login-container">
      <h1 className="login-header">VOLT AUTO</h1>
      <form className="login-form" onSubmit={handleClick}>
        <label htmlFor="phone">Номер телефона:</label>
        <input
          id="phone"
          type="tel"
          className="login-input"
          value={phoneInput}
          maxLength={13}
          onChange={handlePhoneNumber}
        />
        <label htmlFor="password">Пароль:</label>
        <input
          id="password"
          type="password"
          className="login-input"
          value={password}
          onChange={handlePassword}
        />
        {errorState && <div className="error">{errorState}</div>}
        <button type="submit" className="button">
          Войти
        </button>
      </form>
    </div>
  );
}

export default Login;
