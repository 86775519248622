import { useState, useCallback } from "react";
import { add_station } from "../apis/stations";
import "../styles/AddStation.css";

const names = {
  phone: "Номер телефона",
  min_deposit: "Минимальный депозит",
  price_simple: "Цена застоя",
  brand: "Бренд",
  street: "Улица",
  house_number: "Номер дома",
  zip_code: "Zip код",
  city: "Город",
  country: "Страна",
  charge_box_id: "ID станции",
  description: "Описание",
  latitude: "Широта",
  longitude: "Долгота",
  user_id: "ID партнера",
  power: "Мощность",
  partner_percent: "Доля партнера (%)",
};

function FormInput({ name, label, value, onChange }) {
  return (
    <div>
      <p>{label}:</p>
      <input name={name} value={value} onChange={onChange} />
    </div>
  );
}

function AddStation() {
  const [errorState, setError] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [formData, setFormData] = useState(() =>
    Object.keys(names).reduce((acc, key) => ({ ...acc, [key]: "" }), {})
  );

  const handleChange = useCallback((event) => {
    const { name, value } = event.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  }, []);

  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsLoading(true);
    try {
      await add_station(formData);
    } catch (error) {
      setError(`Failed to add station: ${error.message}`);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="AddStation">
      <h1 className="add-station-header">Добавить новую станцию</h1>
      <form onSubmit={handleSubmit}>
        <div className="form-grid">
          {Object.entries(names).map(([key, label]) => (
            <FormInput
              key={key}
              name={key} // Add name attribute here
              label={label}
              value={formData[key]}
              onChange={handleChange}
            />
          ))}
        </div>
        <button type="submit" disabled={isLoading}>
          Добавить
        </button>
      </form>
      {errorState && <p className="error">Error: {errorState}</p>}
      {isLoading && <p className="loading">Loading...</p>}
    </div>
  );
}

export default AddStation;
