import axios from "axios";

const instance = axios.create({
  baseURL: "https://powerbek.uz/api/",
});

const header = {
  accept: "application/json",
  "X-CSRF-TOKEN": "",
  Authorization: `Bearer ${localStorage.getItem("token")}`,
};

export const login = async (phone, password) => {
  const response = await instance.post(
    "login",
    {
      phone,
      password,
    },
    {
      headers: header,
    }
  );

  if (
    response.data.access_token &&
    response.data.data.phone &&
    response.data.data.role
  ) {
    localStorage.setItem("token", response.data.access_token);
    localStorage.setItem("phoneNumber", response.data.data.phone);
    localStorage.setItem("role", response.data.data.role);
  }

  instance.defaults.headers.common[
    "Authorization"
  ] = `Bearer ${response.data.access_token}`;

  return response;
};

export const logout = async () => {
  delete instance.defaults.headers.common["Authorization"];
  localStorage.removeItem("token");
  localStorage.removeItem("phoneNumber");
  localStorage.removeItem("role");
};
