import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { logout } from "../apis/auth";
import "../styles/Bar.css";

const numElements = {
  3: ["Моя история", "Статистика станций", "Станции"],
  5: [
    "Моя история",
    "Общая статистика",
    "Пользователи",
    "Станции",
    "Добавление Станции",
  ],
};

const routes = {
  "Моя история": "history",
  "Общая статистика": "stats",
  "Статистика станций": "stats",
  Пользователи: "users",
  Станции: "stations",
  "Добавление Станции": "addStation",
};

function Bar({ options, setCurrentPage }) {
  const navigate = useNavigate();
  const [phoneNumber, setPhoneNumber] = useState(
    localStorage.getItem("phoneNumber")
  );
  const [errorState, setError] = useState("");

  useEffect(() => {
    // Sync phone number from localStorage, if it updates outside of component
    setPhoneNumber(localStorage.getItem("phoneNumber"));
  }, []);

  const handleLogout = async () => {
    try {
      await logout();
      localStorage.removeItem("currentPage");
      navigate("/login");
    } catch (error) {
      setError(error.message);
    }
  };

  const barElements = (options) => {
    return numElements[options].map((element) => (
      <button
        className="bar-button"
        key={element}
        onClick={() => setCurrentPage(routes[element])}
      >
        {element}
      </button>
    ));
  };

  return (
    <div className="bar-container">
      {barElements(options)}
      <p className="bar-phone-number">+{phoneNumber}</p>
      <button className="bar-button" onClick={handleLogout}>
        Выйти
      </button>
      {errorState && <p className="error">{errorState}</p>}
    </div>
  );
}

export default Bar;
