import { useState, useEffect } from "react";
import { users_list, search_user } from "../apis/users";
import UserHistory from "../components/UserHistory";
import UserEdit from "../components/UserEdit";
import "../styles/Users.css";
import UserBalance from "../components/UserBalance";

const roles = {
  super_admin: "Супер админ",
  admin: "Админ",
  client: "Клиент",
};

function Users() {
  const [errorState, setError] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const [users, setUsers] = useState([]);
  const [userInfo, setUser] = useState(null);
  const [search, setSearch] = useState("");
  const [userIDPhone, setUserIDPhone] = useState({
    ID: "",
    Phone: "",
    Balance: "",
  });
  const [otherPage, setOtherPage] = useState(false);
  const [currentPage, setCurrentPage] = useState("");
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [currentPageSet, setCurrentPageSet] = useState(1);
  const pagesPerSet = 10;

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      try {
        const response = await users_list(page);
        setUsers(response.data);
        setTotalPages(response.meta.last_page);
      } catch (error) {
        setError(error.message);
      } finally {
        setIsLoading(false);
      }
    };
    fetchData();
  }, [page]);

  const pageChange = (newPage) => {
    const newPageSet = Math.ceil(newPage / pagesPerSet);
    if (newPageSet !== currentPageSet) {
      setCurrentPageSet(newPageSet);
    }
    setPage(newPage);
  };

  const nextPageSet = () => {
    setCurrentPageSet(currentPageSet + 1);
  };

  const prevPageSet = () => {
    setCurrentPageSet(currentPageSet - 1);
  };

  const startPage = (currentPageSet - 1) * pagesPerSet + 1;
  const endPage = Math.min(startPage + pagesPerSet - 1, totalPages);

  const handlePageOpen = (type, phone, userID, balance) => {
    setOtherPage(true);
    setCurrentPage(type);
    setUserIDPhone((prev) => ({
      ...prev,
      ID: userID,
      Phone: phone,
      Balance: balance,
    }));
  };

  const handlePageClose = () => {
    setOtherPage(false);
  };

  const renderOtherPage = () => {
    switch (currentPage) {
      case "history":
        return (
          <UserHistory
            userID={userIDPhone.ID}
            userPhone={userIDPhone.Phone}
            handleClose={handlePageClose}
          />
        );
      case "user":
        return (
          <UserEdit
            userID={userIDPhone.ID}
            userPhone={userIDPhone.Phone}
            handleClose={handlePageClose}
          />
        );
      case "balance":
        return (
          <UserBalance
            userPhone={userIDPhone.Phone}
            handleClose={handlePageClose}
          />
        );
      default:
        return null;
    }
  };

  const handleSearch = async () => {
    setIsLoading(true);
    try {
      const response = await search_user(search);
      setUser(response);
    } catch (error) {
      setError("Failed to search the user", error.message);
    } finally {
      setIsLoading(false);
    }
  };

  if (otherPage) {
    return renderOtherPage();
  }

  return (
    <div className="Users">
      <h1 className="users-header">База Пользователей</h1>
      {isLoading ? (
        <p className="loading">Loading...</p>
      ) : errorState ? (
        <p className="error">Error: {errorState}</p>
      ) : (
        <>
          <form>
            <input
              type="text"
              value={search}
              onChange={(event) =>
                setSearch(event.target.value.replace("+", ""))
              }
            />
            <button onClick={handleSearch}>Search</button>
            {search !== "" ? (
              <button
                onClick={() => {
                  setSearch("");
                  setUser(null);
                }}
              >
                Clear
              </button>
            ) : null}
          </form>
          <table>
            <thead>
              <tr>
                <th>Имя</th>
                <th>Фамилия</th>
                <th>Номер телефона</th>
                <th>Роль</th>
                <th>Статистика</th>
                <th>Изменить пользователя</th>
                <th>Изменить баланс</th>
              </tr>
            </thead>
            <tbody>
              {userInfo === null ? (
                users.map((user) => (
                  <tr key={user.id}>
                    <td>{user.name}</td>
                    <td>{user.surname}</td>
                    <td>+{user.phone}</td>
                    <td>{roles[user.role]}</td>
                    <td>
                      <button
                        onClick={() =>
                          handlePageOpen(
                            "history",
                            user.phone,
                            user.id,
                            user.balance
                          )
                        }
                      >
                        Показать
                      </button>
                    </td>
                    <td>
                      <button
                        onClick={() =>
                          handlePageOpen(
                            "user",
                            user.phone,
                            user.id,
                            user.balance
                          )
                        }
                      >
                        Изменить
                      </button>
                    </td>
                    <td>
                      <button
                        onClick={() =>
                          handlePageOpen(
                            "balance",
                            user.phone,
                            user.id,
                            user.balance
                          )
                        }
                      >
                        Изменить
                      </button>
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td>{userInfo.name}</td>
                  <td>{userInfo.surname}</td>
                  <td>+{userInfo.phone}</td>
                  <td>{roles[userInfo.role]}</td>
                  <td>
                    <button
                      onClick={() =>
                        handlePageOpen(
                          "history",
                          userInfo.phone,
                          userInfo.id,
                          userInfo.balance
                        )
                      }
                    >
                      Показать
                    </button>
                  </td>
                  <td>
                    <button
                      onClick={() =>
                        handlePageOpen(
                          "user",
                          userInfo.phone,
                          userInfo.id,
                          userInfo.balance
                        )
                      }
                    >
                      Изменить
                    </button>
                  </td>
                  <td>
                    <button
                      onClick={() =>
                        handlePageOpen(
                          "balance",
                          userInfo.phone,
                          userInfo.id,
                          userInfo.balance
                        )
                      }
                    >
                      Изменить
                    </button>
                  </td>
                </tr>
              )}
            </tbody>
          </table>
          <div className="pagination">
            {currentPageSet > 1 && <button onClick={prevPageSet}>{"<"}</button>}
            {Array.from({ length: endPage - startPage + 1 }, (_, i) => (
              <button
                key={startPage + i}
                className={page === startPage + i ? "active" : ""}
                onClick={() => pageChange(startPage + i)}
              >
                {startPage + i}
              </button>
            ))}
            {endPage < totalPages && (
              <button onClick={nextPageSet}>{">"}</button>
            )}
          </div>
        </>
      )}
      {errorState && <p className="error">Error: {errorState}</p>}
    </div>
  );
}

export default Users;
