import { useState, useEffect } from "react";
import { search_user, balance_update } from "../apis/users";
import "../styles/BalanceUpdate.css";

function UserBalance({ userPhone, handleClose }) {
  const [isLoading, setIsLoading] = useState(false);
  const [errorState, setError] = useState("");
  const [userID, setUserID] = useState(0);
  const [balance, setBalance] = useState("");
  const [amount, setAmout] = useState(0);
  const [action, setAction] = useState("minus");

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      try {
        const response = await search_user(userPhone);
        setUserID(response.id);
        setBalance(response.balance);
      } catch (error) {
        setError(error.message);
      } finally {
        setIsLoading(false);
      }
    };
    fetchData();
  }, [userPhone, balance]);

  const handleChange = (event) => {
    setAmout(event.target.value);
  };

  const handleSignChange = (event) => {
    setAction(event.target.value === "-" ? "minus" : "plus");
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsLoading(true);
    try {
      await balance_update(userID, parseInt(amount), action);
      setBalance(0);
    } catch (error) {
      setError(`Failed to update user's balance: ${error.message}`);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="Users">
      <h1 className="users-header">
        Изменить баланс пользователя: +{userPhone}
      </h1>
      <button
        onClick={() => {
          handleClose();
        }}
      >
        Назад
      </button>
      <h2>Current balance: {balance} sum</h2>
      {isLoading ? (
        <p className="loading">Loading...</p>
      ) : errorState ? (
        <p className="error">Error: {errorState}</p>
      ) : (
        <form onSubmit={handleSubmit}>
          <div className="form-grid">
            <p>Update Balance:</p>
            <select value={action} onChange={handleSignChange}>
              <option value="-">-</option>
            </select>
            <input
              type="number"
              value={amount}
              onChange={handleChange}
              min="0"
            />
          </div>
          <button type="submit" disabled={isLoading}>
            Update Balance
          </button>
        </form>
      )}
    </div>
  );
}

export default UserBalance;
