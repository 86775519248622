import { useState, useEffect, useMemo, useCallback } from "react";
import { user_by_id } from "../apis/users";
import "../styles/Users.css";

function UserHistory({ userID, userPhone, handleClose }) {
  const [errorState, setError] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const [currentUserData, setCurrentUserData] = useState([]);
  const [displayStats, setDisplayStats] = useState([]);
  const itemsPerPage = 10; // Number of items per page for user stats
  const [statsPage, setStatsPage] = useState(1);
  const [statsTotalPages, setStatsTotalPages] = useState(0);

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      try {
        const response = await user_by_id(userID);
        setCurrentUserData(response.reverse());
      } catch (error) {
        setError(error.message);
      } finally {
        setIsLoading(false);
      }
    };
    fetchData();
  }, [userID]);

  const handlePageChange = useCallback(
    (pageNumber) => {
      setStatsTotalPages(Math.ceil(currentUserData.length / itemsPerPage));
      setStatsPage(pageNumber);
      const offset = (pageNumber - 1) * itemsPerPage;
      const slice = currentUserData.slice(offset, offset + itemsPerPage);
      setDisplayStats(slice);
    },
    [currentUserData]
  );

  useEffect(() => {
    if (currentUserData && currentUserData.length > 0) {
      handlePageChange(1); // Initialize with the first page of stats data
    }
  }, [currentUserData, handlePageChange]); // This useEffect runs whenever currentUserData changes

  const formatNumber = (number) => {
    return new Intl.NumberFormat("ru-RU").format(number);
  };

  const summary = useMemo(() => {
    let power = 0,
      sum = 0;
    currentUserData.forEach((data) => {
      power += data.used_kwh;
      sum += parseFloat(data.sum);
    });
    return [power, new Intl.NumberFormat("ru-RU").format(sum)];
  }, [currentUserData]);

  return (
    <div className="Users">
      <h1 className="users-header">Статистика пользователя: +{userPhone}</h1>
      <button
        onClick={() => {
          setCurrentUserData([]);
          setDisplayStats([]);
          setStatsTotalPages(0);
          handleClose();
        }}
      >
        Назад
      </button>
      {isLoading ? (
        <p className="loading">Loading...</p>
      ) : errorState ? (
        <p className="error">Error: {errorState}</p>
      ) : (
        <>
          <div>
            <p>Общее потробление: {summary[0]} kWh</p>
            <p>Общая сумма: {summary[1]} сум</p>
          </div>
          <table>
            <thead>
              <tr>
                <th>Начало загрузки</th>
                <th>ID коннектора</th>
                <th>Использованно kWh</th>
                <th>Сумма</th>
              </tr>
            </thead>
            <tbody>
              {displayStats.length === 0 ? (
                <tr>
                  <td>No History</td>
                </tr>
              ) : (
                displayStats.map((record, index) => (
                  <tr key={index}>
                    <td>
                      {record.start_timestamp
                        .replace("T", " ")
                        .replace(/\.000000Z$/, "")}
                    </td>
                    <td>{record.connector_pk}</td>
                    <td>{record.used_kwh}</td>
                    <td>{formatNumber(record.sum)}</td>
                  </tr>
                ))
              )}
            </tbody>
          </table>
          <div className="pagination">
            {Array.from({ length: statsTotalPages }, (_, i) => (
              <button
                key={i + 1}
                className={statsPage === i + 1 ? "active" : ""}
                onClick={() => handlePageChange(i + 1)}
              >
                {i + 1}
              </button>
            ))}
          </div>
        </>
      )}
    </div>
  );
}

export default UserHistory;
